import {PropertyType, PropertyCardType} from 'src/types/property'
import {useTranslation} from 'next-i18next'
import CustomIconSvg, {
  CustomIconSvgType,
} from 'src/components/elements/icons/svgs/CustomIconSvg'
import clsx from 'clsx'
import {PropertyPromoPositionType} from 'src/types/form'
import {LOCALE_FR} from 'src/constants/locale'
import {useCurrentLocale} from 'src/hooks/locale'

export default function PropertyPromosTags(props: {
  property: PropertyType | PropertyCardType
  className?: string
  white?: boolean
  position: PropertyPromoPositionType
}) {
  const {property, className, position, white = false} = props
  const {t} = useTranslation('common')
  const locale = useCurrentLocale()
  const promo = property.promo || null

  if (!promo) {
    return null
  }
  const promoContent =
    locale === LOCALE_FR
      ? promo.promo_content_fr
      : promo.promo_content_en
      ? promo.promo_content_en
      : promo.promo_content_fr

  if (
    !promoContent &&
    !promo.exclusive_to_mc &&
    !promo.guest_favorite &&
    !promo.lowest_price_guaranteed &&
    !promo.third_night_half_price
  ) {
    return null
  }

  const shouldShow = (
    key:
      | 'exclusive_to_mc'
      | 'guest_favorite'
      | 'lowest_price_guaranteed'
      | 'third_night_half_price',
  ) => {
    if (!promo[key]) {
      return false
    }
    let posKey:
      | 'exclusive_to_mc_positions'
      | 'guest_favorite_positions'
      | 'lowest_price_guaranteed_positions'
      | 'third_night_half_price_positions' = `${key}_positions`

    if (!promo[posKey]) {
      return false
    }

    if (promo[posKey]?.length === 0) {
      return false
    }

    return promo[posKey]?.includes(position)
  }

  return (
    <div
      className={`flex flex-row flex-wrap justify-start items-start gap-2 ${
        className || ''
      }`}
    >
      {promoContent && (
        <TagItem
          content={position === 'unique_property_page' ? t('promo') : undefined}
          white={white}
          icon="promo"
        />
      )}
      {shouldShow('exclusive_to_mc') && (
        <TagItem white={white} content={t('exclusive_to_mc')} icon="diamond" />
      )}
      {shouldShow('guest_favorite') && (
        <TagItem
          white={white}
          content={t('guest_favorite')}
          icon="heart-check"
        />
      )}
      {shouldShow('lowest_price_guaranteed') && (
        <TagItem
          white={white}
          content={t('lowest_price_guaranteed')}
          icon="currency-dollar"
        />
      )}
      {shouldShow('third_night_half_price') && (
        <TagItem
          white={white}
          content={t('third_night_half_price')}
          icon="check"
        />
      )}
    </div>
  )
}

function TagItem(props: {
  content?: string
  icon: CustomIconSvgType
  white?: boolean
}) {
  const {content, icon, white} = props

  return (
    <div
      className={clsx(
        'flex flex-row justify-start items-center gap-2 rounded px-3 py-1',
        white ? 'bg-white/90' : 'bg-accent-300',
      )}
    >
      <CustomIconSvg
        icon={icon}
        className="min-w-[16px] w-[16px] h-[16px] block text-gray-700 "
      />
      {content && (
        <span
          style={{fontSize: '11px', lineHeight: '11px'}}
          className="text-gray-700 mr-2 font-semibold"
        >
          {content}
        </span>
      )}
    </div>
  )
}
