import clsx from 'clsx'
import {makeStyles} from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Favorite from 'src/components/modules/imageSlider/Favorite'
import {stopPropagation} from 'src/utils/dom'
import {globalZIndex} from 'src/utils/styles'

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles(({palette, spacing, breakpoints}) => ({
  btn: {
    display: 'none',
    padding: 0,
    color: palette.common.white,
    background: 'rgba(0, 0, 0, 0.4)',
    position: 'absolute',
    height: spacing(4),
    width: spacing(4),
    zIndex: globalZIndex.moreAboveBase,
    top: '50%',
    transform: 'translateY(-50%)',
    [breakpoints.down(900)]: {
      height: spacing(6),
      width: spacing(6),
    },
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.4)',
    },
  },
  noBg: {
    background: 'none',
    '&:hover': {
      background: 'none',
    },
  },
  nextBtn: {
    right: spacing(0.5),
  },
  prevBtn: {
    left: spacing(0.5),
  },
  favoriteIcon: {
    top: '8px',
    right: '8px',
    width: spacing(4),
    height: spacing(4),
    position: 'absolute',
    background: `rgba(15, 14, 14, 0.72)`,
    borderRadius: '50%',
    padding: spacing(6 / 8),
    color: palette.common.white,
    cursor: 'pointer',
    zIndex: globalZIndex.aboveBase,
  },
  smallBtn: {
    height: spacing(3),
    width: spacing(3),
    [breakpoints.down(900)]: {
      height: spacing(4),
      width: spacing(4),
    },
  },
  nextSmallBtn: {
    right: 0,
  },
  prevSmallBtn: {
    left: 0,
  },
}))

export function CustomButtonArrow(props: {
  onClick?: () => void
  isLeft?: boolean
  noBg?: boolean
  small?: boolean
}) {
  const {onClick, noBg = false, isLeft = false, small = false} = props
  const classes = useStyles()

  let className = [classes.btn, isLeft ? classes.prevBtn : classes.nextBtn]

  if (noBg) {
    className.push(classes.noBg)
  }
  if (small) {
    className.push(classes.smallBtn)
    className.push(isLeft ? classes.prevSmallBtn : classes.nextSmallBtn)
  }

  return (
    <IconButton
      aria-label={isLeft ? 'Left' : 'Right'}
      data-orientation={isLeft ? 'left' : 'right'}
      onClick={stopPropagation(onClick)}
      className={clsx(...className)}
    >
      {isLeft ? <ChevronLeft /> : <ChevronRight />}
    </IconButton>
  )
}

export function FavoriteButton(props: {
  visible?: boolean
  isFavorite?: boolean
  propertyId: number
}) {
  const {visible, isFavorite = false, propertyId} = props
  const classes = useStyles()

  if (!visible) {
    return null
  }

  return (
    <Favorite
      className={classes.favoriteIcon}
      isFavorite={isFavorite}
      propertyId={propertyId}
    />
  )
}
