import {useEffect, useState} from 'react'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import FavoriteRoundedIcon from '@material-ui/icons/FavoriteRounded'
import {
  addToFavoriteApi,
  removeFromFavoritesApi,
} from 'src/services/api/favorite'
import {useIsLoggedIn} from 'src/hooks/user'
import {useTranslation} from 'next-i18next'
import AppPortal from 'src/components/helpers/AppPortal'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

export default function Favorite(props: {
  propertyId: number
  isFavorite: boolean
  className?: string
}) {
  const {propertyId, isFavorite, className = ''} = props
  const [isFav, setIsFav] = useState(isFavorite)
  const [hasCopy, setHasCopy] = useState<boolean>(false)
  const {t} = useTranslation('common')
  const isLoggedIn = useIsLoggedIn()

  const onClick = (event: any) => {
    event.preventDefault()
    event.stopPropagation()

    if (!propertyId) {
      return
    }

    setHasCopy(true)

    if (!isLoggedIn) {
      return
    }

    if (isFav) {
      setIsFav(false)
      removeFromFavoritesApi(propertyId)
      return
    }

    setIsFav(true)
    addToFavoriteApi(propertyId)
  }

  useEffect(() => {
    setIsFav(isFavorite)
  }, [isFavorite])

  return (
    <>
      <AppPortal>
        <Snackbar
          open={hasCopy}
          autoHideDuration={2500}
          onClose={() => setHasCopy(false)}
        >
          <Alert onClose={() => setHasCopy(false)} severity="success">
            {t(
              isLoggedIn
                ? isFav
                  ? 'property_now_added_to_favorite'
                  : 'property_now_removed_from_favorite'
                : 'login_to_use_this_feature',
            )}
          </Alert>
        </Snackbar>
      </AppPortal>

      {isFav ? (
        <FavoriteRoundedIcon className={className} onClick={onClick} />
      ) : (
        <FavoriteBorderIcon className={className} onClick={onClick} />
      )}
    </>
  )
}
