import CalendarCheckIcon from 'src/assets/icons/custom/calendar-check.svg'
import PlaneIcon from 'src/assets/icons/custom/plane.svg'
import FlashIcon from 'src/assets/icons/custom/flash.svg'
import DiamondIcon from 'src/assets/icons/custom/diamond.svg'
import HeartCheckIcon from 'src/assets/icons/custom/check-heart.svg'
import CurrencyDollarIcon from 'src/assets/icons/custom/currency-dollar.svg'
import CheckIcon from 'src/assets/icons/custom/check.svg'
import PromoIcon from 'src/assets/icons/custom/promo.svg'
import PhoneIcon from 'src/assets/icons/custom/phone.svg'
import MessageTextIcon from 'src/assets/icons/custom/message-text.svg'

export type CustomIconSvgType =
  | 'calendar-check'
  | 'plane'
  | 'flash'
  | 'diamond'
  | 'heart-check'
  | 'currency-dollar'
  | 'check'
  | 'promo'
  | 'phone'
  | 'message-text'

function CustomIconSvg(props: {icon: CustomIconSvgType; className?: string}) {
  const {icon, ...otherProps} = props

  switch (icon) {
    case 'promo':
      return <PromoIcon {...otherProps} />
    case 'calendar-check':
      return <CalendarCheckIcon {...otherProps} />
    case 'plane':
      return <PlaneIcon {...otherProps} />
    case 'flash':
      return <FlashIcon {...otherProps} />
    case 'diamond':
      return <DiamondIcon {...otherProps} />
    case 'heart-check':
      return <HeartCheckIcon {...otherProps} />
    case 'currency-dollar':
      return <CurrencyDollarIcon {...otherProps} />
    case 'check':
      return <CheckIcon {...otherProps} />
    case 'phone':
      return <PhoneIcon {...otherProps} />
    case 'message-text':
      return <MessageTextIcon {...otherProps} />
    default:
      return null
  }
}

export default CustomIconSvg
