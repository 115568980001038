import {useEffect, useState} from 'react'

export function useIsVisible(ref: any) {
  const [isIntersecting, setIntersecting] = useState<boolean>(false)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting),
    )

    observer.observe(ref.current)
    return () => {
      observer.disconnect()
    }
  }, [ref])

  return isIntersecting
}

export function useIsTouchDevice() {
  if (typeof window === 'undefined') return false
  return (
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    // @ts-ignore
    navigator.msMaxTouchPoints > 0
  )
}

let leaflet: any | null = null

export async function getLeafletInstance() {
  //client side only
  if (typeof window === 'undefined') {
    return null
  }

  //dont load twice leaflet
  if (leaflet) {
    return leaflet
  }

  leaflet = await import('leaflet')

  return leaflet
}

export function useLeafletInstance() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setRerender] = useState(0)

  useEffect(() => {
    //dont load twice
    if (leaflet) return
    ;(async () => {
      await getLeafletInstance()
      setRerender(1) //make sure to re-render so hook is called again
    })()
  }, [])

  return leaflet
}
